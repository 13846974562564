<template>
	<div class="container mx-auto py-4 px-2 lg:px-28 md:pt-9 text-gray-300">
		<div class="flex items-center justify-center">
			<div>
				<img src="@/assets/dpi-logo.png" width="40" alt="">
			</div>
			<div class="text-center">
				<div class="text-xl font-semibold ml-3 leading-3 font-serif text-red-500">DUTA PERSADA <span class="md:hidden">Instruments</span></div>
				<div class="text-sm text-right hidden md:block">Instruments</div>
			</div>
			<div class="ml-auto hidden md:block">
				<div>WEATHER STATIONS</div>
			</div>
		</div>
		<div class="md:hidden">
			<a href="https://www.tokopedia.com/dutapersada/ambient-weather-station-ws-2000-alt-davis-vantage-vue-pro2-6163-6250" target="_blank" class="flex items-center border border-gray-600 mt-3 p-2">
				<div>
					<img src="https://ecs7.tokopedia.net/img/cache/900/VqbcmM/2021/2/26/0daa3d11-7b25-4887-bcae-42ab40ec5da3.jpg" width="110" alt="">
				</div>
				<div class="flex-grow ml-2 text-sm">
					<div class="text-gray-400">Ambient Weather Station WS-2000 alt Davis Vantage Vue Pro2 6163 6250</div>
					<div class="text-xs text-gray-500">The Ambient Weather WS-2000 is a comprehensive local and remote monitoring package.</div>
				</div>
			</a>
		</div>
		<!-- Widget -->
		<div class="flex flex-col flex-grow flex-shrink mt-3 md:mt-10 text-gray-400">
			<div class="grid md:grid-cols-2 lg:grid-cols-3 gap-2">
				<div>
					<div class="bg-gray-700 h-7 flex items-center px-3 bg-opacity-50">
						<div class="text-sm text-center">TEMPERATURE &#176;C</div>
						<div class="text-sm text-center ml-auto text-yellow-600">Outdor</div>
					</div>
					<div class="relative flex items-center bg-gray-800 h-44 bg-opacity-70 p-5">
						<div class="origin-top-right right-3 top-3 absolute bg-yellow-700 px-1 rounded-sm text-gray-100 text-sm border border-gray-500">{{realTime.temp}} &#176;F</div>
						<div class="absolute right-3 bottom-3">
							<i class="icon-forrst icon-2x text-gray-600"></i>
						</div>
						<div class="bg-gradient-to-r from-red-500 to-yellow-600 w-36 h-32 rounded text-gray-100 flex flex-col items-center justify-center ml-2">
							<div class="text-4xl">{{realTime.tempf}}&#176;C</div>
							<div class="flex justify-center mt-5 divide-x">
								<div class="px-1 text-center">
									<div class="text-xs">Dew point</div>
									<div>{{realTime.dewPoint}}&#176;C</div>
								</div>
								<div class="px-1 text-center">
									<div class="text-xs">Feels Like</div>
									<div>{{realTime.feelsLike}}&#176;C</div>
								</div>
							</div>
						</div>
						<div class="ml-5 text-center">
							<div class="text-sm">From Yesterday</div>
							<div><i class="animate-bounce icon-arrow-down12 text-yellow-600"></i> 0&#176;C</div>
						</div>
					</div>
				</div>
				<div>
					<div class="bg-gray-700 h-7 flex items-center px-3 bg-opacity-50">
						<div class="text-sm text-center ml-auto text-yellow-600">Indor</div>
					</div>
					<div class="relative flex content-center justify-center flex-wrap bg-gray-800 h-44 bg-opacity-70 p-5 space-x-2">
						<div class="absolute right-3 bottom-3">
							<i class="icon-home5 icon-2x text-gray-600"></i>
						</div>
						<div class="flex items-center justify-center space-x-2">
							<div class="border text-center border-gray-600 rounded px-3 py-2 w-36">
								<div>Temperature &#176;C</div>
								<div class="text-4xl mt-2">{{realTime.tempinf}}&#176;C</div>
							</div>
							<div class="border text-center border-gray-600 rounded px-5 py-2 w-36">
								<div>Humidity</div>
								<div class="text-4xl mt-2">{{realTime.humidityin}}%</div>
							</div>
						</div>
						<div class="mx-auto mt-3">
							<div class="grid grid-cols-2 w-52 text-center divide-x divide-gray-600">
								<div>
									<div class="text-xs">Dew Point</div>
									<div class="text-green-500">{{realTime.dewPointin}}°C</div>
								</div>
								<div>
									<div class="text-xs">Feels Like</div>
									<div class="text-green-500">{{realTime.feelsLikein}}°C</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- Forecast -->
				<div>
					<div class="bg-gray-700 h-7 flex items-center px-3 bg-opacity-50">
						<span class="flex h-3 w-3 justify-center items-center">
							<span class="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-green-400 opacity-75"></span>
							<span class="relative inline-flex rounded-full h-2 w-2 bg-green-500"></span>
						</span> 
						<div class="ml-2">Bandung</div>
						<div class="text-sm text-center ml-auto text-yellow-600">Forecast</div>
					</div>
					<div class="relative flex content-center justify-center flex-wrap bg-gray-800 h-44 bg-opacity-70 pb-2 pt-5 space-x-1">
						<div class="h-full w-24 text-center">
							<div class="text-xs">Today <i class="icon-droplet text-blue-400"></i> 64%</div>
							<div v-if="forest.length" class="flex items-center justify-center my-2">
								<div v-if="forest[0].weather[0].id >= 500 && forest[0].weather[0].id <  504">								
									<img width="65" src="https://bmcdn.nl/assets/weather-icons/all/partly-cloudy-day-rain.svg" alt="">
								</div>
								<div v-if="forest[0].weather[0].id >= 520 && forest[0].weather[0].id <  531">
									<svg width="65" xmlns="http://www.w3.org/2000/svg" viewBox="0 110 64 50"><defs><clipPath id="a"><path fill="none" d="M42 64l2.85-17h-23.8L17 64"/></clipPath></defs><g clip-path="url(#a)"><g><path fill="none" stroke="#2885c7" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" d="M24.52 49.05l-1.04 5.9"/><animateTransform attributeName="transform" dur="0.5s" repeatCount="indefinite" type="translate" values="2 -10; -2 10"/><animate attributeName="opacity" dur="0.5s" repeatCount="indefinite" values="1;1;0"/></g><g><path fill="none" stroke="#2885c7" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" d="M31.52 49.05l-1.04 5.9"/><animateTransform attributeName="transform" begin="-0.3s" dur="0.5s" repeatCount="indefinite" type="translate" values="2 -10; -2 10"/><animate attributeName="opacity" begin="-0.3s" dur="0.5s" repeatCount="indefinite" values="1;1;0"/></g><g><path fill="none" stroke="#2885c7" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" d="M38.52 49.05l-1.04 5.9"/><animateTransform attributeName="transform" begin="-0.1s" dur="0.5s" repeatCount="indefinite" type="translate" values="2 -10; -2 10"/><animate attributeName="opacity" begin="-0.1s" dur="0.5s" repeatCount="indefinite" values="1;1;0"/></g></g><path fill="none" stroke="#efefef" stroke-linejoin="round" stroke-width="3" d="M46.5 31.5h-.32a10.49 10.49 0 00-19.11-8 7 7 0 00-10.57 6 7.21 7.21 0 00.1 1.14A7.5 7.5 0 0018 45.5a4.19 4.19 0 00.5 0v0h28a7 7 0 000-14z"/></svg>
								</div>
							</div>
							<div v-if="forest.length" class="text-blue-400">
								{{forest[0].temp.day.toFixed(1)}}°C
							</div>
							<div v-if="forest.length" class="text-red-400">
								{{forest[0].temp.min.toFixed(1)}}°C
							</div>
						</div>
						<div class="w-52 h-40 forecast flex space-x-1 overflow-x-scroll">
							<template v-for="(rain, i) in forest" :key="i">
								<div v-if="!i==0" class="h-full w-12 rounded bg-gray-700 text-center py-2">
									<div>{{rain.dt}}</div>
									<div class="flex items-center justify-center my-3">
										<svg width="65" xmlns="http://www.w3.org/2000/svg" viewBox="0 15 64 50"><defs><clipPath id="a"><path fill="none" d="M42 64l2.85-17h-23.8L17 64"/></clipPath></defs><g clip-path="url(#a)"><g><path fill="none" stroke="#2885c7" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" d="M24.52 49.05l-1.04 5.9"/><animateTransform attributeName="transform" dur="0.5s" repeatCount="indefinite" type="translate" values="2 -10; -2 10"/><animate attributeName="opacity" dur="0.5s" repeatCount="indefinite" values="1;1;0"/></g><g><path fill="none" stroke="#2885c7" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" d="M31.52 49.05l-1.04 5.9"/><animateTransform attributeName="transform" begin="-0.3s" dur="0.5s" repeatCount="indefinite" type="translate" values="2 -10; -2 10"/><animate attributeName="opacity" begin="-0.3s" dur="0.5s" repeatCount="indefinite" values="1;1;0"/></g><g><path fill="none" stroke="#2885c7" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" d="M38.52 49.05l-1.04 5.9"/><animateTransform attributeName="transform" begin="-0.1s" dur="0.5s" repeatCount="indefinite" type="translate" values="2 -10; -2 10"/><animate attributeName="opacity" begin="-0.1s" dur="0.5s" repeatCount="indefinite" values="1;1;0"/></g></g><path fill="none" stroke="#efefef" stroke-linejoin="round" stroke-width="3" d="M46.5 31.5h-.32a10.49 10.49 0 00-19.11-8 7 7 0 00-10.57 6 7.21 7.21 0 00.1 1.14A7.5 7.5 0 0018 45.5a4.19 4.19 0 00.5 0v0h28a7 7 0 000-14z"/></svg>
									</div>
									<div class="text-blue-400 text-xs">
										{{rain.temp.day.toFixed(1)}}°C
									</div>
									<div class="text-red-400 text-xs">
										{{rain.temp.min.toFixed(1)}}°C
									</div>
								</div>						
							</template>
						</div>
					</div>
				</div>
				<!-- /forecast -->

				<!-- Pressure -->
				<div>
					<div class="bg-gray-700 h-7 flex items-center px-3 bg-opacity-50">
						<div class="text-sm text-center ml-auto text-yellow-600">Pressure</div>
					</div>
					<div class="relative flex content-center justify-center flex-wrap bg-gray-800 h-44 bg-opacity-70 p-3">
						<div class="absolute right-3 bottom-3">
							<i class="icon-meter-slow icon-2x text-gray-600"></i>
						</div>
						<div class="absolute flex items-center left-3 rounded-full p-3 text-center border border-gray-600 h-16 w-16">
							<div class="text-blue-500 leading-3">
								0 
								<div class="text-xs text-gray-400 mt-1">hPa/hr</div>
							</div>
						</div>
						<div class="absolute flex items-center justify-center right-3 rounded-full p-3 text-center border border-gray-600 h-16 w-16">	
							
							<div v-if="realTime.baromabsin > lastData.baromabsin" class="text-red-500 animate-pulse">
								<i class="icon-arrow-up16 icon-2x"></i>
							</div>
							<div v-if="realTime.baromabsin == lastData.baromabsin" class="text-gray-300 animate-pulse">
								<i class="icon-menu-close icon-2x"></i>
							</div>
							<div v-if="realTime.baromabsin < lastData.baromabsin" class="text-green-500 animate-pulse">
								<i class="icon-arrow-down16 icon-2x"></i>
							</div>
						</div>
						<div class="text-center">
							<gauge :barometer="realTime.baromabsin" />
							<div>{{realTime.baromabsin}} <span class="text-xs">hPa</span></div>
						</div>
					</div>
				</div>
				<!-- /presure -->
				<!-- Wind -->
				<div>
					<div class="bg-gray-700 h-7 flex items-center px-3 bg-opacity-50">
						<div class="text-sm text-center ml-auto text-yellow-600">Wind</div>
					</div>
					<div class="relative flex items-center content-center justify-center flex-wrap bg-gray-800 h-44 bg-opacity-70 px-5 space-x-2">
						<div class="absolute right-3 bottom-3">
							<i class="icon-weather-windy icon-2x text-gray-600"></i>
						</div>
						<div class="absolute inset-x-2/3 left-0 text-center">
							<div class="text-sm">From</div>
							<div v-if="realTime.winddir > 0 && realTime.winddir < 11" class="text-green-400">N {{realTime.winddir}}&#176;</div>
							<div v-if="realTime.winddir > 10 && realTime.winddir < 33" class="text-green-400">NNE {{realTime.winddir}}&#176;</div>
							<div v-if="realTime.winddir > 32 && realTime.winddir < 56" class="text-green-400">NE {{realTime.winddir}}&#176;</div>
							<div v-if="realTime.winddir > 55 && realTime.winddir < 79" class="text-green-400">ENE {{realTime.winddir}}&#176;</div>
							<div v-if="realTime.winddir > 78 && realTime.winddir < 102" class="text-green-400">E {{realTime.winddir}}&#176;</div>
							<div v-if="realTime.winddir > 101 && realTime.winddir < 125" class="text-green-400">ESE {{realTime.winddir}}&#176;</div>
							<div v-if="realTime.winddir > 124 && realTime.winddir < 148" class="text-green-400">SE {{realTime.winddir}}&#176;</div>
							<div v-if="realTime.winddir > 147 && realTime.winddir < 171" class="text-green-400">SSE {{realTime.winddir}}&#176;</div>
							<div v-if="realTime.winddir > 170 && realTime.winddir < 195" class="text-green-400">S {{realTime.winddir}}&#176;</div>
							<div v-if="realTime.winddir > 194 && realTime.winddir < 218" class="text-green-400">SSW {{realTime.winddir}}&#176;</div>
							<div v-if="realTime.winddir > 217 && realTime.winddir < 241" class="text-green-400">SW {{realTime.winddir}}&#176;</div>
							<div v-if="realTime.winddir > 240 && realTime.winddir < 264" class="text-green-400">WSW {{realTime.winddir}}&#176;</div>
							<div v-if="realTime.winddir > 263 && realTime.winddir < 287" class="text-green-400">W {{realTime.winddir}}&#176;</div>
							<div v-if="realTime.winddir > 286 && realTime.winddir < 310" class="text-green-400">WNW {{realTime.winddir}}&#176;</div>
							<div v-if="realTime.winddir > 309 && realTime.winddir < 333" class="text-green-400">NW {{realTime.winddir}}&#176;</div>
							<div v-if="realTime.winddir > 332 && realTime.winddir < 356" class="text-green-400">NNW {{realTime.winddir}}&#176;</div>
							<div v-if="realTime.winddir > 355 && realTime.winddir == 360" class="text-green-400">N {{realTime.winddir}}&#176;</div>
						</div>
						<div class="absolute inset-x-2/3 right-0 text-center">
							<div class="text-sm">Gusts</div>
							<div class="text-green-400">{{realTime.windgustmph}}</div>
						</div>
						<div class="absolute flex flex-wrap content-center text-center h-40 w-40 border border-gray-500 rounded-full">
							<div class="w-full text-2xl">{{realTime.windspeedmph}}</div>
							<div class="w-full text-xs leading-3">m/sec</div>
							<div class="w-full text-xs mt-3">today's peak</div>
							<div class="w-full text-1xl leading-5">0.0</div>
						</div>
						<div class="compass" :style="{transform: 'rotate('+ realTime.winddir +'deg)'}">
							<svg width="140px" height="154px" viewBox="0 5 232 242" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
								<defs>
									<polygon id="path-1" points="22.633536 0.651702881 0.14674865 0.651702881 0.14674865 37.6096554 22.633536 37.6096554 22.633536 0.651702881"></polygon>
								</defs>
								<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
									<g id="Icons" transform="translate(-36.000000, -622.000000)">
										<g id="Group-6" transform="translate(19.000000, 616.000000)">
											<path d="M233.899113,131.926616 C233.899113,188.058947 188.369519,233.561954 132.208152,233.561954 C76.0451009,233.561954 30.5155064,188.058947 30.5155064,131.926616 C30.5155064,75.7942851 76.0451009,30.2912785 132.208152,30.2912785 C188.369519,30.2912785 233.899113,75.7942851 233.899113,131.926616" id="Fill-1" fill="#FEFEFE" fill-opacity="0" transform="translate(132.207310, 131.926616) rotate(10.000000) translate(-132.207310, -131.926616) "></path>
											<path d="M233.899282,131.926616 C233.899282,188.058947 188.369687,233.561954 132.208321,233.561954 C76.0452694,233.561954 30.5156749,188.058947 30.5156749,131.926616 C30.5156749,75.7942851 76.0452694,30.2912785 132.208321,30.2912785 C188.369687,30.2912785 233.899282,75.7942851 233.899282,131.926616 Z" id="Stroke-3" stroke="#34D399" stroke-width="7.92792" stroke-dasharray="1.582412993907929,6.328066349029542" transform="translate(132.207478, 131.926616) rotate(10.000000) translate(-132.207478, -131.926616) "></path>
											<path d="M247.005342,132.408068 C247.005342,195.335286 195.964412,246.348297 133.002755,246.348297 C70.0410983,246.348297 19.0001685,195.335286 19.0001685,132.408068 C19.0001685,69.4808503 70.0410983,18.4678389 133.002755,18.4678389 C195.964412,18.4678389 247.005342,69.4808503 247.005342,132.408068 Z" id="Stroke-5" stroke="#D97706" stroke-width="3.171168" transform="translate(133.002755, 132.408068) rotate(10.000000) translate(-133.002755, -132.408068) "></path>
											<g id="Group-9" transform="translate(131.624605, 24.708301) scale(-1, 1) rotate(180.000000) translate(-131.624605, -24.708301) translate(120.124605, 5.708301)">
												<mask id="mask-2" fill="white">
													<use xlink:href="#path-1"></use>
												</mask>
												<g id="Clip-8"></g>
												<polygon id="Fill-7" fill="#EF4444" mask="url(#mask-2)" points="11.6395088 0.651197685 0.14674865 37.6096554 11.3901423 30.1175983 22.633536 37.6096554"></polygon>
											</g>
										</g>
									</g>
								</g>
							</svg>
						</div>
					</div>
				</div>
				<!-- /wind -->
				<!-- End -->
				<div class="text-gray-300">
					<div class="bg-gray-700 h-7 grid grid-cols-3 items-center bg-opacity-50 divide-x divide-gray-600">
						<div class="text-sm text-center text-blue-500">Humidity</div>
						<div class="text-sm text-center text-yellow-600">Solar Radiation</div>
						<div class="text-sm text-center text-purple-400">UV index</div>
					</div>
					<div class="grid grid-cols-3 bg-gray-800 h-44 bg-opacity-70 gap-2">
						<div class="py-2 pl-2">
							<div class="bg-gradient-to-t from-blue-900 to-blue-700 h-full text-center flex flex-wrap content-center rounded">
								<div class="w-full text-2xl">{{realTime.humidity}}%</div>
								<div class="w-full text-xs mt-3">From Yesterday</div>
								<div class="w-full">0%</div>
							</div>
						</div>
						<div class="py-2">
							<div class="bg-gradient-to-t from-yellow-900 to-yellow-700 h-full text-center flex flex-wrap content-center rounded">
								<div class="w-full text-2xl">{{realTime.solarradiation}}<span class="text-xs">W/m<sup>2</sup></span></div>
								<div class="w-full"></div>
								<div class="w-full text-xs mt-3">Today's Peak</div>
								<div class="w-full">0W/m<sup>2</sup></div>
							</div>
						</div>
						<div class="py-2 pr-2">
							<div class="relative flex items-center justify-center bg-gradient-to-t from-purple-900 to-purple-700 h-full rounded">
								<div class="text-2xl">{{realTime.uv}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- /widget -->
			<button @click="getApi" class="p-1">get api</button>
		<!-- Footer -->
		<div class="bg-gray-800 opacity-70 mt-2 p-2 text-sm">
			<div class="flex flex-col space-y-2 md:flex-row md:space-y-0 items-center justify-between">
				<div>
					&copy;2021 DUTA PERSADA Instruments
				</div>
				<div>
					<span class="text-red-500">Developed by </span><a href="http://dutapersada.co.id" class="hover:text-green-500" target="_blank">dutapersada.co.id</a>
				</div>
				<div class="flex items-center space-x-2">
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
						<path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
					</svg>
					<div>
						0811-210-6162
					</div>
				</div>
			</div>
		</div>
		<!-- /footer -->
	</div>
</template>

<script>
import { api } from './weather'
import axios from 'axios'
import moment from 'moment'
import Gauge from './components/Gauge'
export default {
	components: {
		Gauge
	},
	data () {
		return{
			forecast: [],
			lastData: {
				baromabsin: null,
				dewPoint: null
			},
			realTime: {
				baromabsin:null,
				dewPoint: null,
				dewPointin: null,
				feelsLike: null,
				feelsLikein: null,
				humidity: null,
				humidityin: null,
				maxdailygust: null,
				solarradiation: null,
				temp: null,
				tempf: null,
				tempinf: null,
				uv: null,
				winddir: null,
				windgustmph: null,
				windspeedmph_avg: null,
				windspeedmph:null
			},
			hPa: null,
		}
	},
	created () {
		this.getApi()
		api.userDevices()
		.then ((devices) => {
			const lastData = devices[0].lastData
			console.log(devices[0]);
			this.realTime.baromabsin = (lastData.baromabsin * 33.86389).toFixed(1)
			this.realTime.dewPoint = ((lastData.dewPoint -32 ) * 5/9).toFixed(1)
			this.realTime.dewPointin = ((lastData.dewPointin -32 ) * 5/9).toFixed(1)
			this.realTime.feelsLike = ((lastData.feelsLike -32 ) * 5/9).toFixed(1)
			this.realTime.feelsLikein = ((lastData.feelsLikein -32 ) * 5/9).toFixed(1)
			this.realTime.humidity = lastData.humidity
			this.realTime.humidityin = lastData.humidityin
			this.realTime.solarradiation = (lastData.solarradiation).toFixed(1)
			this.realTime.temp = lastData.tempf
			this.realTime.tempf = ((lastData.tempf -32 ) * 5/9).toFixed(1)
			this.realTime.tempinf = ((lastData.tempinf -32 ) * 5/9).toFixed(1)
			this.realTime.uv = lastData.uv
			this.realTime.winddir = lastData.winddir
			this.realTime.windgustmph = (lastData.windgustmph * 0.44704).toFixed(1)
			this.realTime.windspeedmph = (lastData.windspeedmph * 0.44704).toFixed(1)

		})
		api.connect()
		
		api.on('data', data => {
			const lastData = data.device.lastData
			this.lastData.baromabsin = (lastData.baromabsin * 33.86389).toFixed(1)
			this.realTime.baromabsin = (data.baromabsin * 33.86389).toFixed(1)
			this.realTime.dewPoint = ((data.dewPoint -32 ) * 5/9).toFixed(1)
			this.realTime.dewPointin = ((data.dewPointin -32 ) * 5/9).toFixed(1)
			this.realTime.feelsLike = ((data.feelsLike -32 ) * 5/9).toFixed(1)
			this.realTime.feelsLikein = ((data.feelsLikein -32 ) * 5/9).toFixed(1)
			this.realTime.humidity = data.humidity
			this.realTime.humidityin = data.humidityin
			this.realTime.solarradiation = (data.solarradiation).solarradiation
			this.realTime.temp = data.tempf
			this.realTime.tempf = ((data.tempf -32 ) * 5/9).toFixed(1)
			this.realTime.tempinf = ((data.tempinf -32 ) * 5/9).toFixed(1)
			this.realTime.uv = data.uv
			this.realTime.winddir = data.winddir
			this.realTime.windgustmph = (data.windgustmph * 0.44704).toFixed(1)
			this.realTime.windspeedmph = (data.windspeedmph * 0.44704).toFixed(1)
		})
		api.subscribe('c86407315228452d8df49a62edd4d64a828790ac45c348ebb07b865fb5c0a6aa')
	},
	computed: {
		forest () {
			return this.forecast.filter(item => {
				return item.dt = moment(item.dt * 1000).format('dd')
			})
		}
	},
	methods: {
		
		getApi () {
			axios.get('https://api.openweathermap.org/data/2.5/onecall?lat=-6.917252&lon=107.626731&units=metric&appid=79118cc9e4c9bd3828e8b14b6922bf18')
			.then((res) => {
				this.forecast = res.data.daily
				console.log(this.forecast);
			})
		}
	}
}
</script>

<style>
	*::-webkit-scrollbar {
		width: 0;
	}

	*::-webkit-scrollbar-track {
		background: transparent;
	}

	*::-webkit-scrollbar-thumb {
		background: transparent;
		border: none;
	}
	.compass {
		transition: -webkit-transform 4000ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
		transition: transform 4000ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
		transition: transform 4000ms cubic-bezier(0.68, -0.55, 0.265, 1.55), -webkit-transform 4000ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
	}
</style>

