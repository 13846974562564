<template>
    <div class="text-gray-50" id="segmented_gauge"></div>
</template>

<script>

import * as d3 from '../d3.min.js'
var updateBarometer = ''
export default {
    props: ['barometer'],
    mounted () {
        this.getData()
    },
    methods: {
        getData () {
            var app = this
            var _segmentedGauge = function(element, size, min, max, sliceQty) {
                if (typeof d3 == 'undefined') {
                    console.warn('Warning - d3.min.js is not loaded.');
                    return;
                }

                // Initialize chart only if element exsists in the DOM
                if(element) {

                    // Main variables
                    var d3Container = d3.select(element),
                        width = size,
                        height = (size / 2) + 30,
                        radius = (size / 2),
                        ringInset = 15,
                        ringWidth = 10,

                        pointerWidth = 10,
                        pointerTailLength = 5,
                        pointerHeadLengthPercent = 0.75,
                        
                        minValue = min,
                        maxValue = max,
                        
                        minAngle = -90,
                        maxAngle = 90,
                        
                        slices = sliceQty,
                        range = maxAngle - minAngle,
                        pointerHeadLength = Math.round(radius * pointerHeadLengthPercent);

                    // Colors
                    var colors = d3.scale.linear()
                        .domain([0, slices - 1])
                        .interpolate(d3.interpolateHsl)
                        .range(['#66BB6A', '#EF5350']);


                    // Create chart
                    // ------------------------------

                    // Add SVG element
                    var container = d3Container.append('svg');

                    // Add SVG group
                    var svg = container
                        .attr('width', width)
                        .attr('height', height);


                    // Construct chart layout
                    // ------------------------------
                    
                    // Donut  
                    var arc = d3.svg.arc()
                        .innerRadius(radius - ringWidth - ringInset)
                        .outerRadius(radius - ringInset)
                        .startAngle(function(d, i) {
                            var ratio = d * i;
                            return deg2rad(minAngle + (ratio * range));
                        })
                        .endAngle(function(d, i) {
                            var ratio = d * (i + 1);
                            return deg2rad(minAngle + (ratio * range));
                        });

                    // Linear scale that maps domain values to a percent from 0..1
                    var scale = d3.scale.linear()
                        .range([0, 1])
                        .domain([minValue, maxValue]);
                        
                    // Ticks
                    var ticks = scale.ticks(slices);
                    var tickData = d3.range(slices)
                        .map(function() {
                            return 1 / slices;
                        });

                    // Calculate angles
                    function deg2rad(deg) {
                        return deg * Math.PI / 180;
                    }

                    // Wrap paths in separate group
                    var arcs = svg.append('g')
                        .attr('class', 'd3-slice-border')
                        .attr('transform', "translate(" + radius + "," + radius + ")");

                    // Add paths
                    arcs.selectAll('path')
                        .data(tickData)
                        .enter()
                        .append('path')
                        .attr('fill', function(d, i) {
                            return colors(i);
                        })
                        .attr('d', arc);

                    // Wrap text in separate group
                    var arcLabels = svg.append('g')
                        .attr('transform', "translate(" + radius + "," + radius + ")");

                    // Add text
                    arcLabels.selectAll('text')
                        .data(ticks)
                        .enter()
                        .append('text')
                        .attr('class', 'd3-text opacity-50')
                        .attr('transform', function(d) {
                            var ratio = scale(d);
                            var newAngle = minAngle + (ratio * range);
                            return 'rotate(' + newAngle + ') translate(0,' + (10 - radius) + ')';
                        })
                        .style({
                            'text-anchor': 'middle',
                            'font-size': 11,
                            'fill' : '#F3F4F6'
                        })
                        .text(function(d) { return d });

                    // Line data
                    var lineData = [
                        [pointerWidth / 2, 0], 
                        [0, -pointerHeadLength],
                        [-(pointerWidth / 2), 0],
                        [0, pointerTailLength],
                        [pointerWidth / 2, 0]
                    ];

                    // Create line
                    var pointerLine = d3.svg.line()
                        .interpolate('monotone');

                    // Wrap all lines in separate group
                    var pointerGroup = svg
                        .append('g')
                        .data([lineData])
                        .attr('transform', "translate(" + radius + "," + radius + ")");

                    // Paths
                    var pointer = pointerGroup
                        .append('path')
                        .attr('d', pointerLine)
                        .attr('transform', 'rotate(' + minAngle + ')')
                        .style({
                            'fill':'#EF4444'
                        });

                    // Random update
                    // ------------------------------

                    // Update values
                    updateBarometer = function () {
                        var ratio = scale(app.barometer);
                        var newAngle = minAngle + (ratio * range);
                        pointer.transition()
                            .duration(2500)
                            .ease('elastic')
                            .attr('transform', 'rotate(' + newAngle + ')');
                    }
                    updateBarometer();
                }
            };

            _segmentedGauge("#segmented_gauge", 200, 900, 1090, 9);
            
           
        }
    },
    watch: {
        barometer (val) {
            updateBarometer()
        }
    }
}
</script>
